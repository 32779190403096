<template>
  <div class="functions">
    <functions-list />
  </div>
</template>

<script>
import FunctionsList from "./pages/FunctionsList.vue";

export default {
  name: "Functions",
  components: {
    FunctionsList,
  },
};
</script>
