<template>
    <div class="function-toolbar">
        <v-toolbar class="ma-pa-0" flat tile dense>

            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        plain
                        color="secondary"
                        v-bind="attrs"
                        v-on="on" 
                        @click="select_all_items()">
                        <v-icon>{{selectBtnIcons[actions_btns_status.selectBtnIcon]}}</v-icon>
                    </v-btn>
                </template>
                <span>{{$t("message.btns.select-all")}}</span>
            </v-tooltip>

            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        plain
                        color="secondary"
                        v-bind="attrs"
                        v-on="on" 
                        @click="refresh_items()">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>
                <span>{{$t("message.btns.refresh")}}</span>
            </v-tooltip>

            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :class="{'d-none': actions_btns_status.deleteBtnHide}"
                    icon 
                    plain 
                    color="secondary"
                    v-bind="attrs"
                    v-on="on" 
                    @click="delete_items()">
                    <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <span>{{$t("message.btns.delete")}}</span>
            </v-tooltip>
            
        </v-toolbar>
    </div>
</template>

<script>

    export default {
        name: 'CoreActionToolbar',

        data () {
            return {
                selectBtnIcons: {
                    "none":"mdi-checkbox-blank-outline",
                    "full":"mdi-checkbox-marked",
                    "some":"mdi-checkbox-intermediate"
                },
            }
        },
        props: {
            select_all_callback: Function,
            refresh_items_callback: Function,
            delete_all_selected_callback: Function,
            actions_btns_status:{
                type: Object,
                default: function () {
                    return {
                        selectBtnIcon:  'none',
                        deleteBtnHide: true,
                    }
                }
            }
        },
        methods: {
            delete_items(){
                this.delete_all_selected_callback()
            },
            refresh_items(){
                this.refresh_items_callback()
            },
            // Select the checkbox for all rows and change Select Icon in the Actions toolbar
            select_all_items(){
                this.select_all_callback()
            }
        }
        
    }
</script>
