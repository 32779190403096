import axios from "axios";
import connectionMixin from "@/mixins/connectionParams.js";

export default {
  mixins: [connectionMixin],
  data() {
    return {
      deletingFunctionName: [],
      runFunctionName: "",
      deleteDialog: false,
      runDialog: false,
      runResponseDialog: "",
      successMessage: false,
      successMessageText: "",
      errorMessage: false,
      errorMessageText: "",
      enableCreateButton: true,
      search: "",
      selected: [],
      functionList: [],
      functionTypes: {
        NODEJS: "mdi-nodejs",
        RUBY: "mdi-language-ruby",
        PYTHON: "mdi-language-python",
        GO: "mdi-language-go",
        JAVA: "mdi-language-java",
        PHP: "mdi-language-php",
      },
    };
  },
  created() {
    this.getFunctionsList();
  },
  computed: {
    headersTable() {
      return [
        {
          text: this.$t("message.functionlist.table.column.name").toUpperCase(),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "functions",
        },
      ];
    },
    functionItems() {
      return this.functionList;
    },
  },

  methods: {
    redirectToCreateNew() {
      this.$router.push({ name: "Choose a new language for the function", params: { items: this.functionList }});
    },

    redirectToFunctionDetails(func) {
      this.$router.push({
        name: "Function details",
        params: {
          id: func.name,
          language: func.language,
          readOnly: true,
        },
      });
    },
    //Loads the Functions list
    getFunctionsList() {
      axios
        .get(this.apihost + "/api/v1/files", this.opts)
        .then((response) => {
          this.functionList = [];
          let data = response.data;
          data.forEach(element => {
            element.folders.forEach(folder => {
              this.functionList.push({name: folder.folderName, language: element.language});
            });
          });
        })
        .catch((err) => {
          console.error("failed to retrieve function list", err);
          if (err.response.status == 403) {
            this.enableCreateButton = false;
          }
        });
    },
    //Open the delete function dialog
    deleteFunctionDialog(names) {
      this.deleteDialog = true;
      this.deletingFunctionName = names;
    },
    //Delete Functions in Serverless environment
    deleteFunction(name) {
      let func = this.functionList.filter(f => f.name == name);
      let path = {
        language: func[0].language,
        folderName: func[0].name,
        fileName: func[0].name + "-1_0_0.tar.gz",
      };
      this.opts.data = path;
      return axios
        .delete(this.apihost + "/api/v1/services/" + name, this.opts)
        .then(() => {
          this.successMessage = true;
          this.successMessageText = this.$t(
            "message.functionlist.snackbar.function_deleted",
            { name: name }
          );
          this.deleteDialog = false;
          this.refresh_table();
        })
        .then(() => {
          if (this.selected.length == 0) {
            this.change_functions_btns_status("none", true);
          }
        });
    },
    deleteItems(names) {
      if (!Array.isArray(names)) {
        this.deleteFunction(names);
      } else if (names.length > 1) {
        for (let index = 0; index < names.length; index++) {
          const name = names[index];
          this.deleteFunction(name);
        }
      } else {
        const name = names[0];
        this.deleteFunction(name);
      }
      this.selected = [];
    },

    //Delete selected functions
    delete_all_selected() {
      try {
        let selectedItems = this.selected;
        let names = [selectedItems[0].name];
        for (let index = 1; index <= selectedItems.length - 1; ++index) {
          names.push(selectedItems[index].name);
        }
        this.deleteFunctionDialog(names);
      } catch (e) {
        console.log("error", e);
        return null;
      }
    },
    //Change the Function toolbar icons status:
    //Show/Hide the Delete icon
    //Sets the name of Select icon
    change_functions_btns_status(sel, del) {
      this.functionsStatus.selectBtnIcon = sel;
      this.functionsStatus.deleteBtnHide = del;
    },
    //Select the checkbox for all rows
    // and sets the name of Select Icon in the Functions toolbar
    select_all_rows() {
      if (this.$refs.table_functions.everyItem === false) {
        this.$refs.table_functions.toggleSelectAll(true);
        this.change_functions_btns_status("full", false);
        return "full";
      } else {
        this.$refs.table_functions.toggleSelectAll(false);
        this.change_functions_btns_status("none", true);
        return "none";
      }
    },
    // Show Delete icon if the checkbox in any row is selected
    // and sets the name of Select Icon in the Functions toolbar
    select_row() {
      if (this.$refs.table_functions.someItems === true) {
        this.change_functions_btns_status("some", false);
      } else {
        this.change_functions_btns_status("none", true);
      }
    },
    //Refresh functions table
    refresh_table() {
      this.getFunctionsList();
    },
  },
};
