export default {

    methods: {
      handlingError(status, functionName) {
        switch (status) {
          case 409:
            this.errorMessageText = this.$t('message.error.function.function_associate_error', { name: functionName });
            break
          case 401:
            this.errorMessageText = this.$t('message.error.function.unauthorized_error');
            break
          case 403:
            this.errorMessageText = this.$t('message.error.function.forbidden_error', { name: functionName });
            break
          case 404:
            this.errorMessageText = this.$t("message.error.function.service_not_found");
            break
          case 500:
            this.errorMessageText = this.$t('message.error.function.server_error', { name: functionName });
            break
          case 502:
            this.errorMessageText = this.$t('message.error.function.bad_gateway');
            break;
          case 503:
            this.errorMessageText = this.$t('message.error.function.service_unavailable_error');
            break
        }
        this.errorMessage = true

  
      },
  
    }
  }