<template>
  <v-container fluid>
    <core-toolbar-name
      :page-name="this.$t('message.pages.title.function_list')"
    ></core-toolbar-name>

    <!-- Communication message to the user -->
    <v-snackbar v-model="successMessage" top right color="success">
      <span class="text-uppercase">
        {{ successMessageText }}
      </span>
    </v-snackbar>
    <v-snackbar v-model="errorMessage" top right color="error">
      <span class="text-uppercase">
        {{ errorMessageText }}
      </span>
    </v-snackbar>
    <!-- end Communication message to the user -->

    <v-card tile flat>
      <v-row class="d-flex justify-end align-center" v-if="enableCreateButton">
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="6"
          xl="4"
          class="d-flex align-center align-self-center"
        >
          <v-text-field
            class="mx-4 pt-0 mt-0 align-self-center"
            v-model="search"
            :label="$t('message.labels.search')"
            append-icon="mdi-magnify"
            clearable
            single-line
            solo
            dense
            hide-details="true"
          ></v-text-field>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="enableCreateButton"
                class="mx-4"
                v-bind="attrs"
                v-on="on"
                color="secondary"
                @click="redirectToCreateNew"
              >
                {{ $t("message.btns.create_new") }}
                {{ $tc("message.labels.function", 1) }}
              </v-btn>
            </template>
            <span
              >{{ $t("message.btns.add-new") }}
              {{ $tc("message.labels.function", 1) }}</span
            >
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <core-toolbar-actions
            :select_all_callback="select_all_rows"
            :delete_all_selected_callback="delete_all_selected"
            :actions_btns_status="functionsStatus"
            :refresh_items_callback="refresh_table"
          >
          </core-toolbar-actions>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-data-table
        ref="table_functions"
        v-model="selected"
        :headers="headersTable"
        :hide-default-header="true"
        :items="functionItems"
        :search="search"
        item-key="name"
        show-select
        :single-select="false"
        @item-selected="select_row"
        @click:row="redirectToFunctionDetails"
      >
        <template v-slot:no-data>
          {{ $t("message.labels.no-deployed-functions") }}
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-icon>
            {{ functionTypes[item.language] }} 
          </v-icon>
          <span class="ps-5 item-title-text">{{ item.name }}</span>
        </template>
        <template v-slot:[`item.functions`]="{ item }">
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group>
                <v-list-item @click.stop="deleteFunctionDialog(item.name)">
                  <v-list-item-icon>
                    <v-icon color="secondary">mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("message.btns.delete")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <v-row v-if="!enableCreateButton">
        <v-col
          cols="12"
          class="text-h4 d-inline-flex flex-column justify-center align-center"
        >
          <span class="grey--text text--lighten-1 my-10">
            {{ $t("message.error.function.permission_error") }}
          </span>
        </v-col>
      </v-row>
    </v-card>

    <!-- Dialogs -->
    <v-row justify="center">
      <v-dialog
        v-model="deleteDialog"
        persistent
        width="auto "
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card>
          <v-card-title class="headline secondary white--text">
            {{ $t("message.functionlist.dialog.delete.delete") }}
            {{ $tc("message.labels.function", deletingFunctionName.length) }}
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text class="pt-4 body-1">
            {{
              $t("message.functionlist.dialog.delete.sure", {
                item: $t("message.labels.function"),
              })
            }}:
            <span class="font-weight-bold">{{
              deletingFunctionName.toString().replace(/,/g, ", ")
            }}</span>
            ?
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn color="accent" text @click="deleteDialog = false">
              {{ $t("message.btns.no") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="deleteItems(deletingFunctionName)"
            >
              {{ $t("message.btns.yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="runDialog"
        persistent
        scrollable
        width="auto "
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card>
          <v-card-title class="headline secondary white--text">
            {{ $t("message.functionlist.dialog.run.run_result") }}
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text>
            <p class="font-weight-bold mt-4 text-h6">{{ runFunctionName }}</p>
            <pre>{{ runResponseDialog }}</pre>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions fixed>
            <v-spacer />
            <v-btn color="primary" text @click="runDialog = false">
              {{ $t("message.btns.close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- end Dialogs -->
  </v-container>
</template>

<script>
import CoreToolbarActions from "@/components/core/ToolbarActions.vue";
import CoreToolbarName from "@/components/core/ToolbarName.vue";
import ModalDeleteTrigger from "@/components/ModalDeleteTrigger.vue";
import functionListMixin from "@/mixins/views/pages/FunctionsList.js";
import errorHandlingMixin from "@/mixins/components/core/ErrorHandling.js";

export default {
  name: "FunctionsList",
  components: {
    CoreToolbarActions,
    CoreToolbarName,
    ModalDeleteTrigger,
  },
  data() {
    return {
      functionsStatus: {
        selectBtnIcon: "none",
        deleteBtnHide: true,
      },
    };
  },
  mixins: [functionListMixin, errorHandlingMixin, ModalDeleteTrigger],

  methods: {},
};
</script>

<style scoped>
/* Hide the table header checkbox to select all */
.v-card >>> table th .v-simple-checkbox {
  display: none;
}
/* Apply fixed width to the first and others td*/
.v-card >>> table tbody tr > td {
  width: 56px;
}
/* Apply auto width to the td that come after the first one*/
.v-card >>> table tbody tr > td ~ td {
  width: auto;
  cursor: pointer;
}
.item-title-text {
  vertical-align: middle;
}
</style>
