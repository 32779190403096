<template>
  <v-dialog
    v-model="deleteTriggerModalOpened"
    persistent
    width="auto "
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="iconBtn"
        icon
        color="error"
        v-bind="attrs"
        v-on="on"
        @click.stop="openDeleteTriggerDialog()"
      >
        <v-icon>mdi-link-off</v-icon>
      </v-btn>
      <v-list-item v-else @click.stop="openDeleteTriggerDialog()">
        <v-list-item-icon>
          <v-icon color="red">mdi-link-off</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("message.btns.withdraw")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title class="headline secondary white--text">
        {{ $t("message.functionlist.dialog.delete.delete") }}
        {{ $tc("message.labels.trigger", 1) }}
      </v-card-title>
      <v-spacer></v-spacer>
      <v-card-text class="pt-4 body-1">
        {{ $t("message.functionlist.dialog.retire.sure") }}
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn color="accent" text @click="deleteTriggerModalOpened = false">
          {{ $t("message.btns.no") }}
        </v-btn>
        <v-btn color="primary" text @click="deleteTrigger(triggerName)">
          {{ $t("message.btns.yes") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import connectionMixin from "@/mixins/connectionParams.js";
import axios from "axios";

export default {
  name: "ModalDeleteTrigger",
  mixins: [connectionMixin],
  props: {
    iconBtn: Boolean,
    triggerName: String,
    triggerExist: Boolean,
  },
  data: () => ({
    deleteTriggerModalOpened: false,
    successMessage: false,
    successMessageText: "",
  }),
  methods: {
    //Open the delete trigger dialog
    openDeleteTriggerDialog() {
      this.deleteTriggerModalOpened = true;
    },
    //Delete Trigger
    deleteTrigger(name) {
      axios
        .delete(this.apihost + "/triggers/" + name, this.opts)
        .then((response) => {
          if (response.status === 204) {
            this.successMessage = true;
            this.successMessageText = this.$t(
              "message.functionlist.snackbar.trigger_deleted",
              { name: name }
            );
            this.deleteTriggerModalOpened = false;
            this.$emit("update:triggerExist", false);
          }
        });
    },
  },
};
</script>
